.Contact-sec2-Container {
  display: flex;
  flex-direction: column;
  padding: 25px 0 75px 100px;
  height: 960px;

  @media (max-width: 864px) {
    padding: 50px 35px;
  }
}

.contact-sec2head {
  font-family: Questrial;
  font-weight: 400;
  letter-spacing: -0.02em;
  text-align: left;
  color: #230e0e;
  position: relative;
  padding-top: 75px;

  @media (min-width: 1024px) {
    font-size: 55px;
    line-height: 50px;
  }

  &::after {
    content: "  ";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 120px;
    border-bottom: 4px solid #e31e27;
  }

  @media (max-width: 920px) {
    &::after {
      content: "  ";
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 50px;
      border-bottom: 3px solid #e31e27;
    
  }
}
}
.contact-sec2para {
  font-family: Figtree;
  /* font-style: italic; */
  font-weight: 300;
  letter-spacing: -0.02em;
  text-align: left;
  color: #230E0E;
  margin-bottom: 24px;
  line-height: 50px;
}

.contactFormContainer {
  padding-top: 50px;
  width: 90%;

  @media (max-width: 854px) {
    width: 100%;
    padding-top: 0;
  }
}

.contact-form {
  /* max-width: 400px; */
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-form h2 {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  color: #344054;
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #344054;
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.form-group textarea {
  height: 160px;
  resize: none;
}

.button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #e31e27;
  font-size: 16px;
  font-family: Inter;
  font-weight: 600;
  line-height: 24px;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 50px;
}

.map-reponsive {
  overflow: hidden;
  position: relative;
  height: 100%;

  @media (max-width: 600px) {
    /* padding-bottom: 56.25%; */
  }
}

.map-reponsive iframe {
  left: 0;
  top: 0;
  border: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
