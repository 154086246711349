.valuesContainer {
  background-color: #000;
  padding: 75px 0 75px 75px;

  @media (max-width: 920px) {
    padding: 50px 35px;
    gap: 2rem;
  }

  & .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;

    @media (min-width: 1024px) {
     width: 600px;
    }

    & .header {
      font-family: Questrial;
      font-weight: 400;
      letter-spacing: -0.02em;
      color: #fff;
      position: relative;

      @media (min-width: 1024px) {
        font-size: 55px;
        line-height: 70px;
      }

      &::after {
        content: " ";
        position: absolute;
        bottom: -5px;
        left: 5px;
        width: 110px;
        height: 5px;
        border-bottom: 4px solid #e31e27;
      }

      @media (max-width: 854px) {
        &::after {
          content: " ";
          position: absolute;
          bottom: -5px;
          left: 0px;
          width: 60px;
          height: 3px;
          border-bottom: 3px solid #e31e27;
        }
      }
    }

    & .summary {
      order: 1;

      font-family: Figtree;
      font-weight: 400;
      letter-spacing: 0em;
      text-align: justify;
      padding-top: 24px;
      color: #fff;
      
      @media (min-width: 1024px) {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  & .imgContainerRight {

    @media (max-width: 920px) {
      order: -1;
    }
    
    @media (min-width: 1024px) {
      -webkit-clip-path: polygon(100% -220%, 0% 100%, 100% 100%);
    }
  }
}


.leadersSection {
  width: 100%;
  padding: 75px;

  @media (max-width: 920px) {
    padding: 50px 35px;
  }
}

.growthStorySection {
  width: 100%;
  height: 115vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* align-items: center; */

  @media (max-width: 1180px) {
    height: 100%;
    padding: 50px 35px;
  }

  & .header {
    /* font-size: 35px; */
    font-weight: 500;
    font-family: Questrial;
    color: #000;
    /* margin: 100px 0; */
    position: relative;
    padding: 0 75px;
    height: 80px;

    &::after {
      content: "  ";
      position: absolute;
      bottom: 10px;
      left: 75px;
      width: 105px;
        border-bottom: 4px solid #e31e27;
    }

    @media (max-width: 854px) {
      padding: 0;
      &::after {
        content: " ";
        position: absolute;
        bottom: 40px;
        left: 0px;
        width: 60px;
        height: 3px;
        border-bottom: 3px solid #e31e27;
      }
    }
  }

  & .horizontalLine {
    position: relative;
    top: 263px;
    display: block;
    height: 1px;
    border: 0;
    border-bottom: 1px solid #717171;
    margin: 1em 0;
    padding: 0;
  }

  & .timeLineWrapper {
    display: flex;
    position: absolute;
    width: 90vw;

    & .timeLine {
      color: #c1c1c1;
      position: relative;
      width: 400px;
      height: 250px;
      bottom: -2px;
      left: 50px;

      & .eventBubble {
        position: absolute;
        bottom: 0;
        left: -4px;
        height: 12px;
        width: 12px;
        background: #f6c7c9;
        border-radius: 50%;
        display: inline-block;
      }

      & .eventLine {
        content: " ";
        position: absolute;
        top: 0px;
        bottom: 10px;
        left: 0px;
        border-left: 2px dotted #797979;

        &::after {
          content: " ";
          position: absolute;
          left: -7px;
          height: 12px;
          width: 12px;
          background-color: #717171;
          border-radius: 50%;
          display: inline-block;
        }
      }
    }

    & .eventSummary {
      padding-left: 18px;
      text-align: center;
      color: #717171;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;

      & .year {
        font-family: Figtree;
        /* font-size: 28px; */
        font-weight: 800;
        /* line-height: 40px; */
        letter-spacing: -0.02em;
        text-align: left;
        color: #e31e27;
      }

      & .title {
        font-family: Figtree;
        /* font-size: 20px; */
        font-weight: 700;
        color: #230E0E;
        /* line-height: 34px; */
        letter-spacing: -0.02em;
        text-align: left;
      }

      & .summary {
        font-family: Figtree;
        /* font-size: 20px; */
        font-weight: 400;
        /* line-height: 34px; */
        letter-spacing: -0.02em;
        text-align: justify;
      }
    }
  }

  & .timeLineWrapper2 {
    display: flex;
    position: absolute;
    width: 85vw;

    & .timeLine {
      color: #c1c1c1;
      position: relative;
      width: 400px;
      height: 250px;
      bottom: -240px;
      left: 150px;

      & .eventBubble {
        position: absolute;
        bottom: 0;
        left: -4px;
        height: 12px;
        width: 12px;
        background: #717171;
        border-radius: 50%;
        display: inline-block;
      }

      & .eventLine {
        content: " ";
        position: absolute;
        top: 0px;
        bottom: 10px;
        left: 0px;
        border-left: 2px dotted #797979;

        &::after {
          content: " ";
          position: absolute;
          left: -7px;
          height: 12px;
          width: 12px;
          background-color: #f6c7c9;
          border-radius: 50%;
          display: inline-block;
        }
      }
    }

    & .eventSummary {
      padding-left: 18px;
      text-align: center;
      color: #717171;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
      height: 100%;

      & .year {
        font-family: Figtree;
        /* font-size: 28px; */
        font-weight: 800;
        /* line-height: 40px; */
        letter-spacing: -0.02em;
        text-align: left;
        color: #e31e27;
      }

      .contentContainer {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-top: 50px;
      }

      & .title {
        font-family: Figtree;
        /* font-size: 20px; */
        font-weight: 700;
        color: #230E0E;
        /* line-height: 34px; */
        letter-spacing: -0.02em;
        text-align: left;
      }

      & .summary {
        font-family: Figtree;
        /* font-size: 20px; */
        font-weight: 400;
        /* line-height: 34px; */
        letter-spacing: -0.02em;
        text-align: justify;
      }
    }
  }
}

.aboutSection {
  padding-top: 150px;
  padding-left: 0;

  & .Abtcontent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    text-align: justify;
    /*width: 85%;*/

    & .Abtheader {
      font-family: Questrial;
      font-size: 55px;
      font-weight: 400;
      line-height: 70px;
      letter-spacing: -0.02em;
      position: relative;
      /*padding-right: 390px;*/

      &::after {
        content: "  ";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 120px;
          border-bottom: 4px solid #e31e27;
      }
    }

    & .Abtsummary {
      font-family: Figtree;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: justify;
    }
  }
  & .AbtimgContainer {
    /* width: 745px;
    height: 745px; */
  }
}

.aboutSection2 {
  padding: 0px;
  background-color: #000000;

  .Abtcontent2 {
    padding-left: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: justify;
    gap: 20px;
    /* width: 95%; */
  }

  .Abtheader2 {
    font-family: Questrial;
    font-size: 55px;
    font-weight: 400;
    line-height: 70px;
    letter-spacing: -0.02em;
    position: relative;
    /*padding-right: 380px;*/
    color: #ffffff;
    text-align: justify;

    &::after {
      content: "  ";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 120px;
        border-bottom: 4px solid #e31e27;
    }
  }

  .Abtsummary2 {
    color: #ffffff;
    font-family: Figtree;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: justify;
  }

  .Abtimgcontainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Abtimgcontainer img {
    width: 100%;
    height: auto;
  }
}

.Abttop {
  letter-spacing: -0.02em;
  font-family: Questrial;
  font-weight: 400;
  /* padding-left: 75px; */
  position: relative;
  display: inline;

  @media (min-width: 1024px) {
    font-size: 55px;
    line-height: 70px;
  }

  &::after {
    content: " ";
    position: absolute;
    bottom: -5px;
    left: 5px;
    width: 110px;
    height: 5px;
    border-bottom: 4px solid #e31e27;
  }

  @media (max-width: 854px) {
    &::after {
      content: " ";
      position: absolute;
      bottom: -5px;
      left: 0px;
      width: 50px;
      height: 3px;
      border-bottom: 3px solid #e31e27;
    }
  }
}

.grid-container {
  padding: 50px;
}

.grid-item {
  position: relative;
  width: fit-content;
  padding: 24px;
}

.grid-item img {
  width: 100%;
  height: auto;
}

.wrapper {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
}

.Abttext-box {
  flex-direction: column;
  position: absolute;
  bottom: 15%;
  left: 31%;
  transform: translateX(-20%);
  background-color: rgba(147, 149, 151, 0.7);
  color: #000000;
  display: flex;
  padding: 13px;
  width: 180px;
  
  @media (max-width: 1024px) {
    padding: 5px;
    width: 65%;
  }
}

.Abthead {
  font-family: Figtree;
  font-size: 12px;
  line-height: 10px;
  font-weight: 600;
  color: #ffffff;
  text-align: left;
  padding-left: 15px;
  gap: 16px;
  padding-bottom: 08px;
}

.Abtpara {
  font-family: Figtree;
  font-size: 10px;
  line-height: 10px;
  font-weight: 400;
  color: #ffffff;
  text-align: left;
  padding-left: 15px;
  gap: 4px;
}

.timelineDesktop {
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
}


.timelineMobile {
  display: block;

  @media (min-width: 1024px) {
    display: none;
  }
}
