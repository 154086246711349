.introSection {
  padding: 24px 0;

  @media (max-width: 854px) {
    padding: 24px 35px;
    gap: 1rem;
  }

  & .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;

    @media (min-width: 1024px) {
     width: 625px;
    }

    & .header {
      font-family: Questrial;
      font-weight: 400;
      letter-spacing: -0.02em;
      color: #230E0E;
      position: relative;
      text-transform: capitalize;

      @media (min-width: 1024px) {
        font-size: 55px;
        line-height: 70px;
      }

      &::after {
        content: " ";
        position: absolute;
        bottom: -5px;
        left: 5px;
        width: 110px;
        height: 5px;
        border-bottom: 4px solid #e31e27;
      }

      @media (max-width: 854px) {
        &::after {
          content: " ";
          position: absolute;
          bottom: -5px;
          left: 0px;
          width: 60px;
          height: 3px;
          border-bottom: 3px solid #e31e27;
        }
      }
    }

    & .summary {
      font-family: Figtree;
      /* font-size: 20px; */
      font-weight: 400;
      letter-spacing: 0em;
      text-align: justify;
      padding-top: 24px;
      color: #475467;
      
      @media (min-width: 1024px) {
        line-height: 30px;
        width: 580px;
      }
    }

    & .summary2 {
      font-family: Figtree;
      /* font-size: 20px; */
      font-weight: 400;
      letter-spacing: 0em;
      text-align: left;
      padding-top: 24px;
      color: #475467;

      
      @media (min-width: 1024px) {
        line-height: 30px;
      }
    }
  }

  & .imgContainer{

    @media (min-width: 1024px) {
      -webkit-clip-path: polygon(0% -220%, 100% 100%, 0% 100%);
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;

    }
  }

  & .imgContainerl{

    @media (min-width: 1024px) {
      -webkit-clip-path: polygon(0% -220%, 100% 100%, 0% 100%);
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;

    }
  }

  & .imgContainerRight {

    @media (max-width: 920px) {
      order: -1;
    }

    @media (min-width: 1024px) {
      -webkit-clip-path: polygon(100% -220%, 0% 100%, 100% 100%);
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;

    }
  }
}
