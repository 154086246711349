.contactSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;

  @media (max-width: 600px) {
    flex-direction: row;
  }
}

.bg-contact {
  padding: 75px 50px;
  background-color: #f9fafb;
}

.ContactLeContainer {
  display: flex;
  flex-direction: column;
  flex: 0.3;
  padding-bottom: 48px;
  padding-left: 45px;
}

.Callusheadle {
  font-family: Questrial;
  font-weight: 400;
  letter-spacing: -0.02em;
  text-align: left;
  color: #230e0e;
  position: relative;

  @media (min-width: 1024px) {
    font-size: 55px;
    line-height: 50px;
  }

  &::after {
    content: "  ";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 120px;
    border-bottom: 4px solid #e31e27;
  }
}

.parale {
  font-family: Figtree;
  /* font-size: 16px; */
  font-style: italic;
  font-weight: 300;
  /* line-height: 50px; */
  letter-spacing: -0.02em;
  text-align: left;
  padding-top: 12px;
}

.contactlist {

}

.Callushead {
  color: #230e0e;
  font-family: Figtree;
  /* font-size: 20px; */
  font-weight: 600;
  /* line-height: 30px; */
  letter-spacing: 0em;
  text-align: left;
  position: relative;
}

.Callushead1{
  color: #475467;
  font-family: Figtree;
  /* font-size: 20px; */
  font-weight: 700;
  /* line-height: 30px; */
  letter-spacing: 0em;
  text-align: left;
  position: relative;
}

.contact-info{
  display: flex;
 
}

.contact-info h6 {
  margin-right: 5px; /* Adjust the margin as needed */
}

.para {
  color: #475467;
  font-family: Figtree;
  /* font-size: 16px; */
  font-weight: 400;
  /* line-height: 24px; */
  letter-spacing: 0em;
  text-align: left;
  
}

.para1 {
  color: #475467;
  font-family: Figtree;
  /* font-size: 16px; */
  font-weight: 400;
  /* line-height: 24px; */
  letter-spacing: 0em;
  /* padding-bottom: 01px; */
  text-align: left;

  @media (max-width: 920px) {
     padding-top: 05px;
  }
} 

.callUsContainer {
  display: flex;
  flex: 1;

  @media (max-width: 820px) {
    flex-direction: column;
  }

  & .call-us {
    flex: 0.7;
  }
}

.contactInformation {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
}
