.introSectionContainer {
  @media (min-width: 756px) {
    padding-left: 75px;
  }
}

.growthSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 100px 35px;

  @media (max-width: 920px) {
    padding: 50px 35px;
  }

  & .col-container {
    /* display: flex; */
    justify-content: space-evenly;
  }


  & .growthLogo {
    width: fit-content;
    height: fit-content;
  }

  & .summary {
    font-family: Figtree;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    width: 450px;
  }
}

.workSection {
  padding: 100px 75px;
  background-color: #131313;
  height: 100%;

  @media (max-width: 600px) {
    padding: 50px 35px;
  }

  & .content {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;

    @media (min-width: 1024px) {
      width: 450px;
    }

    @media (max-width: 600px) {
      width: 100%;
      gap: 6px;
    }

    & .header {
      font-family: Questrial;
      font-weight: 400;
      letter-spacing: -0.02em;
      color: #fff;
      position: relative;

      @media (min-width: 1024px) {
        font-size: 55px;
        line-height: 70px;
      }

      &::after {
        content: "  ";
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 110px;
          border-bottom: 4px solid #e31e27;
      }
    }

    & .summary {
      font-family: Figtree;
      font-weight: 400;
      letter-spacing: 0em;
      /* text-align: justify; */
      color: #fff;
      padding-top: 8px;

      @media (min-width: 1024px) {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  & .workFields {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 48px;

    @media (max-width: 600px) {
      padding-top: 28px;
    }

    & .fieldsCardContainer {
      display: flex;
      gap: 24px;
      align-items: center;

      & .imgContainer {
        width: fit-content;
        height: fit-content;

        & img {
          border-radius: 10px;
        }
      }

      & .content {
        & .title {
          font-family: Figtree;
          /* font-size: 24px; */
          font-weight: 500;
          /* line-height: 32px; */
          letter-spacing: -0.02em;
          text-align: left;
          color: #fff;

          @media (max-width: 600px) {
            /* font-size: 16px;
            line-height: 25px; */
          }
        }

        & .summary {
          font-family: Figtree;
          /* font-size: 16px; */
          font-weight: 400;
          /* line-height: 24px; */
          letter-spacing: 0em;
          color: #acacac;

          @media (max-width: 600px) {
            /* font-size: 10px;
            line-height: 16px; */
          }
        }
      }
    }
  }
}

.statsSection {
  height: 60vh;
  padding: 75px;

  @media (max-width: 600px) {
    padding: 35px;
  }

  & .title {
    font-family: Questrial;
  font-weight: 400;
  letter-spacing: -0.02em;
  text-align: left;
  color: #230e0e;
  position: relative;

  @media (min-width: 1024px) {
    font-size: 55px;
    line-height: 50px;
  }

  &::after {
    content: "  ";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 120px;
    border-bottom: 4px solid #e31e27;
  }
}

  & .tagLine {
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
    color: #475467;
  }

  & .statsContainer {
    margin-top: 75px;
  }

  & .statsData {
    display: flex;
    align-items: center;
    gap: 20px;
    font-family: Inter;

    & .verticalLine {
      border-left: 2px solid #ed1c25;
      height: 92px;
    }

    & .metrics {
      display: flex;
      flex-direction: column;
      justify-content: center;

      & .numbers {
        font-family: Inter;
        font-size: 48px;
        font-weight: 600;
        line-height: 60px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #ed1c25;
      }

      & .fields {
        font-family: Inter;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: #230e0e;
      }
    }
  }
}

.testimonials {
  background: #fbfbfb;
  padding:100px 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  & .carousel-indicators {
    display: none;
  }

  & .carousel-control-prev {
    /* display: none; */
  }

  .carousel-control-next {
    /* display: none; */
  }

  & .testimonial-wrapper {
    display: flex;
    justify-content: space-evenly;
    /* gap: 80px; */

    @media (max-width: 600px) {
      flex-wrap: wrap;
    }

    & .companyLogo {
      width: fit-content;
      height: fit-content;
    }

    & .reviewContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 50%;

      & .reviewer {
        display: flex;
        flex-direction: column;
        gap: 2px;
      }

      & .review {
        font-family: Figtree;
        font-weight: 400;
        letter-spacing: -0.02em;
        text-align: left;
        color: #230e0e;

        @media (min-width: 1024px) {
          font-size: 36px;
          line-height: 44px;
        }
      }

      & .name {
        font-family: Inter;
        font-weight: 600;
        letter-spacing: 0em;
        text-align: left;
        color: #230e0e;

        @media (min-width: 1024px) {
          font-size: 18px;
          line-height: 28px;
        }
      }

      & .role {
        font-family: Inter;
        font-weight: 400;
        letter-spacing: 0em;
        text-align: left;
        color: #475467;

        @media (min-width: 1024px) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}
