.footerWrapper {
    /* padding-top: 150px; */
}

.phils {
    padding-top: 24px;

    & :first-child {
        padding-top: 0;
    }
}