.philsInfo {
  padding-top: 96px;

  @media (max-width: 920px) {
    padding-top: 0;
  }
}

.sectorInfoSection {
  padding: 120px 0 0 0px;
  display: flex;

  @media (max-width: 920px) {
    padding: 50px 35px;
    gap: 1rem;
  }

  & .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;

    @media (min-width: 1024px) {
      width: 650px;
      padding-left: 75px;
    }

    & .header {
      font-family: Questrial;
      font-weight: 400;
      letter-spacing: -0.02em;
      position: relative;

      @media (min-width: 1024px) {
        font-size: 55px;
        line-height: 70px;
      }

      &::after {
        content: "  ";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 110px;
        border-bottom: 4px solid #e31e27;
      }

      @media (max-width:920px) {
        &::after {
          content: "  ";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 50px;
          border-bottom: 3px solid #e31e27;
        }
      }
    }

    & .subHeader {
      font-family: Figtree;
      /* font-style: italic; */
      font-weight: 300;
      letter-spacing: -0.02em;
      text-align: left;
      color: #230E0E;
      margin-bottom: 24px;

      @media (min-width: 1024px) {
        font-size: 16px;
      }
    }

    & .summary {
      font-family: Figtree;
      font-weight: 400;
      letter-spacing: 0em;
      text-align: justify;
      padding-top: 24px;

      @media (min-width: 1024px) {
        font-size: 20px;
        line-height: 30px;
      }
      
    }
  }

  & .imgContainer {

    @media (max-width: 920px) {
      order: -1;
    }

    @media (min-width: 1024px) {
      -webkit-clip-path: polygon(0% -220%, 100% 100%, 0% 100%);
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & .imgContainerl {

    @media (max-width: 920px) {
      order: -1;
    }

    @media (min-width: 1024px) {
      -webkit-clip-path: polygon(100% -220%, 0% 100%, 100% 100%);
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
