.footerSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
}

.bg-footer {
  background-color: #000000;
  padding: 50px 25px 25px 50px;

  @media (max-width: 1024px) {
    padding: 50px 35px 25px 35px;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  padding: 0 100px;
}

.image {
  max-width: 10%;
  height: auto;
  margin-left: 04%;
  margin-top: 0%;
}

.footer-link {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.footerLogoContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  & .para {
    font-family: Figtree;
    font-weight: 300;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
    padding-top: 8px;
  }

  @media (min-width: 746px) {
    justify-content: center;
    padding-left: 40px;
  }

  @media (min-width: 1024px) {
    padding-left: 100px;
    
  }
}

.list {
  padding-bottom: 24px;

  @media (max-width: 1024px) {
    padding-left: 12px;
  }
}

.footer-link {
  & a,
  span {
    color: #acacac;
    font-family: Figtree;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    cursor: pointer;
  }
}

.footer-heading {
  color: #ffffff;
  font-family: Figtree;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.content-wrapper {
  display: flex;
}

.copyRightContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  & .footer-alt {
    color: #acacac;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  & .socialIconContainer {
    display: flex;
  }
}

.footer-social-icons {
  list-style: none;
  padding: 0;
  margin: 80px 10px 0;
  display: flex;
}

.footer-social-icon a {
  color: #ffffff;
  text-decoration: none;
}

.facebook {
  background-color: #4e71a8;
  color: #ffffff;
}

.linkedin {
  background-color: #4e71a8;
  color: #ffffff;
}
