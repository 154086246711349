*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.html {
  font-size: 62.5%;
}

.navbar input[type="checkbox"],
.navbar .hamburger-lines {
  display: none;
}


.navbar {
  width: 100%;
  background: #fff;
  color: #000;
  opacity: 0.85;
  z-index: 100;
  position: sticky;
  top: 0;
  padding: 0;
  cursor: pointer;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 64px;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0 50px;
}

.menu-items {
  order: 2;
  display: flex;
  gap: 12px;
}

.menu-items li {
  list-style: none;
  margin-left: 1rem;
  font-size: 1rem;
  position: relative;
}

.menu-items li:hover {
  text-decoration: none;
}

.menu-items li::after {
  content: "";
  height: 3px;
  width: 0%;
  background: #e31e27;
  position: absolute;
  left: 1px;
  bottom: -5px;
  transition: width 0.3s ease-in-out;
}

.menu-items li:hover::after {
  width: 50%;
}

.navbar {

  & span {
    color: #475467;
    text-decoration: none;
    font-weight: 600;
    transition: color 0.3s ease-in-out;
    font-family: Syne;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
 
}

.active {
  color: #000 !important;

  &::after {
    content: "  ";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 40px;
     border-bottom: 3px solid #e31e27;
  }
}

.navbar span:hover {
  color: #000;
}

.headerLogo {
  height: 80px;
  width: 120px;
  display: block;

  @media (max-width: 864px) {
    display: none;
  }

  & img {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 768px) {
  .navbar {
    opacity: 1;
  }

  .navbar-container input[type="checkbox"],
  .navbar-container .hamburger-lines {
    display: block;
  }

  .navbar-container {
    display: block;
    position: relative;
    height: 64px;
  }

  .navbar-container input[type="checkbox"] {
    position: absolute;
    display: block;
    height: 32px;
    width: 30px;
    top: 20px;
    left: 20px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }

  .navbar-container .hamburger-lines {
    display: block;
    height: 28px;
    width: 35px;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .navbar-container .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: #333;
  }

  .navbar-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.3s ease-in-out;
  }

  .navbar-container .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }

  .navbar-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.3s ease-in-out;
  }

  .navbar .menu-items {
    padding-top: 100px;
    background: #fff;
    height: 100vh;
    max-width: 300px;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    margin-left: -50px;
    padding-left: 40px;
    transition: transform 0.5s ease-in-out;
    box-shadow: 5px 0px 10px 0px #aaa;
    overflow: hidden;
  }

  .navbar .menu-items li {
    margin-bottom: 1.8rem;
    font-size: 1.1rem;
    font-weight: 500;
  }

  .logo {
    position: absolute;
  }

  .navbar-container input[type="checkbox"]:checked ~ .menu-items {
    transform: translateX(0);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(45deg);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
    transform: scaleY(0);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
    transform: rotate(-45deg);
  }
}

@media (max-width: 500px) {
  .navbar-container input[type="checkbox"]:checked ~ .logo {
    display: none;
  }
}

.section-heading {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.logo-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.logo img {
  height: auto;
  max-width: 100%;
}


.dropbtn {
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  color: #475467;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease-in-out;
  font-family: Syne;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.dropdown {
  position: relative;
  display: inline-block;
  margin-right: 8px;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: -80px;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content span {
  color: #475467;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-family: Figtree;
}

.dropdown-arrow {
  height: 0;
  width: 0;
	border-left:6px solid transparent;
	border-right:6px solid transparent;
	border-top: 6px solid #333;
  position: absolute;
  top: 10px;
  right: -15px;
}

.dropdown:hover .dropdown-arrow {
  top: 5px;
	border-left:6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #333;
  border-top: 6px solid transparent;
}

.dropdown-content span:hover {background-color: #f1f1f1;}
.dropdown:hover .dropdown-content {display: block;}